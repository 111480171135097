@import "./../fonts/ncc-icons/style.css";
@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";

html, body {
  height: 100%;
  margin: 0;
}

.layout {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.layout header,
.layout footer {
  flex-shrink: 0;
}

.layout main {
  flex: 1 0 auto;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

.icon-large {
  font-size: 1.5rem;
}

.icon-xl {
  font-size: 2rem;
}

.bg-gray-900 {
  background-color: $gray-900
}

.bg-black {
  background-color: $black
}

.text-small {
  font-size: $small-font-size;
}

.text-extra-small {
  font-size: $small-font-extra-size;
}

.brand img {
  max-height: 30px;
}

.border-grey-400 {
  border-color: $gray-400 !important;
}

.border-grey-900 {
  border-color: $gray-900 !important;
}

.goto {
  position: absolute;
  right: .5rem;
  bottom: .5rem;
}

.bg-black {
  background: #000;
}

.bg-gray {
  background-color: $gray-200;
}

.text-gray-600 {
  color: $gray-600 !important;
}

.blog-tile-item .image {
  background-size: cover;
}

.opacity-50 {
  opacity: .5;
}

.socials {
  font-size: 1rem;

  a:hover {
    color: inherit;
    opacity: .8;
  }

  i {
    font-size: 1.2rem;
  }
}

.category-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .category {
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
    border: 1px $primary solid;
  }

  .category:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    .category {
      margin: 0;
      width: 31%;
    }
  }

  .category__link {
    display: block;
    padding: 2rem;
  }

  .category__icon {
    width: 100px;
    margin-bottom: 1rem;
  }

  .category__link:hover {
    color: inherit;
  }

  .category__range {
    font-size: .8rem;
    text-transform: uppercase;
  }

  .ncc-arrow-right {
    float: right;
    color: $primary;
    margin-right: .5rem;
  }

  .category__title {
    font-size: 1.2rem;
  }
}


.category__list {
  .list-group__item {

    .item__content {
      max-width: 900px;
    }

    &:last-child {
      border-bottom: 0;
    }

    .title {
      flex: 1 1 auto;
      padding-right: 1rem;
    }

    .link {
      display: block;
    }

    .number {
      vertical-align: top;
      flex: 0 0 auto;
      min-width: 2rem;
    }

    .icon {
      flex: 0 0 auto;
      font-size: 1.5rem;
    }
  }
}

.container {
  @include media-breakpoint-down(lg) {
    max-width: none;
  }
}


@import "layout/font";
@import "layout/form";
@import "layout/list";
@import "layout/page";

main {
  width: 100%;
}

@import "components/header";
@import "components/nav";
@import "components/search";
@import "components/button";
@import "components/contact-bar";
@import "components/newsletter-form";

@import "components/footer";
@import "components/sponsor-list";
@import "components/donate-form";

@import "components/post/tile";
@import "components/post/youtube-player";
@import "components/post/social-share";
@import "components/post/header-image";
@import "components/qna/category";
@import "components/qna/category_tile";
@import "components/qna/item";
