@font-face {
  font-family: 'ncc-icons';
  src:
    url('fonts/ncc-icons.ttf?6laf78') format('truetype'),
    url('fonts/ncc-icons.woff?6laf78') format('woff'),
    url('fonts/ncc-icons.svg?6laf78#ncc-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ncc-"], [class*=" ncc-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ncc-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ncc-arrow-left:before {
  content: "\e901";
}
.ncc-arrow-right:before {
  content: "\e900";
}
.ncc-camera:before {
  content: "\e902";
}
.ncc-chat:before {
  content: "\e903";
}
.ncc-notes:before {
  content: "\e904";
}
.ncc-search:before {
  content: "\e905";
}
