//.btn-primary,
//.btn-primary:hover,
//.btn-primary:active,
//.btn-primary:focus,
//.btn-primary:visited {
//  color: #fff;
//  background-color: $primary;
//  border-color: $primary;
//}
//
//.btn-primary:hover {
//  opacity: .6;
//}
//
//.btn-primary:not(:disabled):not(.disabled):active,
//.btn-primary:not(:disabled):not(.disabled).active,
//.show > .btn-primary.dropdown-toggle {
//  background-color: $primary;
//  border-color: $primary;
//}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}
