.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list {
  border-top: 1px $primary solid;
}

.list__item {
  line-height: 1.4;
  border-bottom: 1px $primary solid;
  display: flex;
}

.item__number {
  min-width: 2rem;
  text-align: right;
  margin-right: 1rem;
}

.item__number--part {
  min-width: 3rem;
}

.list__link {
  margin: 0 auto;
  padding: .5rem;
  display: flex;
  width: 100%;
}

.item__label {
  flex: 1 1 auto;
}


@include media-breakpoint-up(md) {
  .list__item {
    font-size: 1.25rem;
  }

  .item__number {
    margin-right: 3rem;
  }

  .list__link {
    padding: .75rem 1rem;
  }

  .item__number {
    margin-right: 2rem;
  }
}
