$primary: #E61D42 !default;
$primaryDarker: #b91432 !default;
$black: #000 !default;
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

$small-font-size: 90% !default;
$small-font-extra-size: 80% !default;
$font-weight-base: 400 !default;
$input-placeholder-color: $gray-400 !default;
$btn-border-radius: 0 !default;
$link-color: $black !default;
