.page__header-image {
  position: relative;
  overflow: hidden;
  min-height: 360px;
}

.page__header-image:before {
  content: '';
  display: block;
  padding-top: 30%;
}

.header-image__img {
  width: 100%;
  height: auto;
  min-height: 100%;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}
