.newsletter-form label {
  display: block;
  margin: 0;
}

.newsletter-form .actions input {
  width: 100%;
}

@include media-breakpoint-up(md) {
  .newsletter-form {
    min-width: 456px;
  }
}
