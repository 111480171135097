.donate-form p {
  margin: 0 0 1rem;
}

.donate-form label img {
  display: none !important;
}

.donate-form br {
  display: none;
}

@include media-breakpoint-up(sm) {
  .donate-form select,
  .donate-form .field-currency > span:last-child {
    width: 50% !important;
  }
}
