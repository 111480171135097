.footer {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px $gray-200 solid;

  .author {
    order: 1
  }
}

.about-site__link {
  color: $primary;
}

.about-site__brand img {
  max-width: 160px; // need to be set correctly
}

.about-site__text {
  margin-top: .75rem;
  margin-left: 36px;
}

.footer__title {
  margin-top: -3px;
  text-transform: uppercase;
}

.footer__bar {
  border-top: 1px $gray-200 solid;
}

.footer__widgets {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 1rem;
}

.footer__widget {
  margin-bottom: 1rem;
}

.newsletter__widget {
  .wpcf7-validation-errors {
    display: none !important;
  }

  .wpcf7-not-valid-tip {
    color: $danger;
    font-style: italic;
    font-size: $small-font-size;
    padding: .375rem .75rem;
  }

  .actions {
    flex: 1;
    z-index: 5;
  }

  .actions .btn {
    width: 100%;
  }
}



@include media-breakpoint-up(md) {
  .footer {
    .author {
      order: 0;
      flex: 1 1 auto;
    }

    .extra-links {
      flex: 0 0 auto;
    }
  }
}

@include media-breakpoint-up(lg) {
  .footer {
    margin-top: 2rem;
    padding-top: 2rem;
  }

  .footer__widgets {
    flex-direction: row;
  }
}

@include media-breakpoint-up(xl) {
  .footer__widgets {
    padding: 0;
  }
}
