.page__youtube-bar {
  background-color: $gray-100;
  padding: 2.5rem;
}

.youtube-player {
  position: relative;
  width: 100%;
  max-width: 800px;
}

.youtube-player:before {
  display: block;
  content: '';
  width: 100%;
  padding-top: 56.25%;
}

.youtube-player__iframe {
  border: 0;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

