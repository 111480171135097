.contact-bar {
  position: relative;
  margin-top: 2rem;
  background-color: $gray-100;
}

.contact-bar__title {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 400;
}

.contact-bar__image {
  display: none;
  position: absolute;
  width: 50%;
  height: 100%;
  background-size: cover;
  background-color: $primary;
  background-image: url('../../../statics/odb/contactbar.jpg')
}

.contact-bar__container {
  display: flex;
  position: relative;
  z-index: 100;
  justify-content: space-between;
}

.contact-bar__explanation {
  color: #fff;
  display: none;
  align-content: center;
  justify-items: center;

  h4 {
    flex: 1 0 auto;
    font-size: 1.85rem;
    font-weight: 400;
    text-align: center;
    align-self: center;
    text-shadow: 1px 1px 3px rgba(0,0,0,.5);
  }
}

.contact-bar__form {
  width: 100%;
}

@include media-breakpoint-up(md) {
  .contact-bar__container {
    width: 100%;
  }

  .contact-bar__explanation {
    display: flex;
  }

  .contact-bar__image {
    display: block;
  }

  .contact-bar__form,
  .contact-bar__explanation {
    width: 50%;
  }
}

