.page__social-share {
  font-size: $small-font-size;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  margin-bottom: 1rem;
}

.page__social-share .socials {
  display: inline-block;
  margin: 0;
}

.socials__label {
  line-height: 1.5rem;
}

.social__item {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  position: relative;
  overflow: hidden;

  .social__icon {
    font-size: 1.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
