header {
  .navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .brand {
    flex: 0 0 auto;
  }

  .brand__link {
    display: block;
    cursor: pointer;
  }

  .brand__image {
    pointer-events: none;
    display: block;
    height: 30px;
  }
}

.header {
  position: relative;

  .header__cta {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.25rem;

    @include media-breakpoint-up(lg) {
      bottom: 5rem;
    }
  }

  .header__image {
    min-height: 400px;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(lg) {
      min-height: 500px;
      background-size: 100% auto;
      background-position: center;
    }
  }
}

.header__description {
  max-width: 50%;
}


