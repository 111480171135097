.post-tiles {
  margin: 1rem 0 -1rem;
  display: flex;
  flex-wrap: wrap;
}

.post-tile {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 280px;
  background-color: $gray-100;
  margin: 0 0 1rem;
  justify-content: space-between;

  .ncc-arrow-right {
    position: absolute;
    color: $primary;
    right: 1rem;
    bottom: 1rem;
    font-size: 23px;
  }
}

.post-tile__link {
  height: 100%;
  display: block;
  cursor: pointer;
}

.post-tile__image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.post-tile__link:hover {
  color: inherit;
}

.post-tile__name {
  font-weight: 600;
  line-height: 1.2;
}

.post-tile__name,
.post-tile__type {
  margin-bottom: .5rem;
}

.post-tile__content {
  margin-bottom: 2rem;
}

.post-tile__author {
  margin-bottom: .5rem;
}

.post-tile__author,
.post-tile__content {
  font-size: 90%;
  font-weight: 400;
}

.post-tile__type {
  font-size: 80%;
  color: #6c757d;
}

.post-tile__header {
  flex: 0 0 auto;
  width: 100% !important;
  background-size: cover;
  background-color: $primary;
}

.post-tile__body {
  flex-grow: 1;
  position: relative;
  padding: 1rem;
}

@include media-breakpoint-up(md) {
  .post-tiles {
    justify-content: space-between;
  }

  .post-tile__image {
    max-height: 180px;
  }

  .post-tile {
    width: 49%;
    margin: 0 0 1rem;
  }
}


@include media-breakpoint-up(lg) {
  .post-tile {
    width: 100%;
  }

  .post-tile__header {
    width: 40% !important;
    overflow: hidden;
    position: relative;
  }

  .post-tile__image {
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  .post-tiles {
    margin: 2rem 0 -1rem;
  }

  .post-tile {
    flex-direction: row;

    &:last-child {
      margin-right: 0;
    }
  }

  .post-tile__body {
    padding: 1.5rem;
  }

  .post-tile:nth-child(even) .post-tile__header {
    order: 3;
  }

}








