h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: .5rem;
  font-family: 'Source Sans Pro', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 600;
  line-height: 1.4;
}

h1, .h1 {
  font-size: 2rem;
}

strong {
  font-weight: bold;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-700 {
  font-weight: 600;
}

a:hover {
  color: $primary;
  text-decoration: none;
}

.merriweather {
  font-weight: 300;
  font-family: 'Merriweather', serif;
}

blockquote {
  margin-top: -16px;
  font-size: 1.75rem;
  position: relative;
  float: right;
  margin-right: -170px;
  font-family: 'Merriweather','Book Antiqua','Palatino', 'Georgia', serif;
  line-height: 3rem;
  padding-bottom: 2.5rem;
  width: 340px;
  margin-left: 1.5rem;
  text-align: center;
}

blockquote p em::before,
blockquote p em::after {
  display: inline-block;
  color: $primary;
}

blockquote p em::before {
  content: '“';
  margin-right: .25rem;
}

blockquote p em::after {
  content: '”';
  margin-left: .25rem;
}


@include media-breakpoint-down(md) {
  .xs-break-brs br {
    content: ''
  }
  .xs-break-brs br:after {
    content: "\00a0"
  }
}
