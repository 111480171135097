.sponsor-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sponsor-list__item {
  flex: none;
  margin-bottom: 1rem;
}

.sponsor-list__item:nth-child(4n) {
  flex-basis: 100%;
}

.sponsor-list__note {
  display: inline-block;
  font-size: 80%;
  margin-bottom: .25rem;
}

.sponsor-list__link,
.sponsor-list__link:hover {
  color: inherit;
}


