.page__heading {
  text-align: center;
  color: white;
  background: $primary;
  padding: 2rem 0;
}

.page__content {
  padding: 1rem 0 0;
}

.page__footer {
  padding: 1rem .5rem;
}

.page__article {
  margin: 0 1rem;

  h1, h2, h3 {
    color: $primary
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.page__navigation {
  color: #fff;
  background: $primaryDarker;
  padding: 1rem;

  > .container {
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  .navigation__link {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
  }
}

@include media-breakpoint-up(xl) {
  .page__navigation {
    padding: 1rem 0;
  }

  .page__heading {
    padding: 3rem 0;
  }

  .page__title {
    max-width: 800px;
    margin: 0 auto;
    font-size: 2.5rem;
  }

  .page__article {
    margin: 0 auto;
  }

  .page__article--small {
    max-width: 800px;
  }

  .page__content {
    padding: 2rem 0 0;
  }

  .btn-nav {
    font-size: 1.4rem;
  }
}
