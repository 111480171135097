$primary: #4bb5ad;
$primaryDarker: #3ca596;

@import "./base";
@import "./theme";

.footer .brand img {
  max-width: 160px;
}

.footer .gt-info {
  position: relative;
  margin-left: 36px;
}

.footer .author {
  opacity: .5;
}
