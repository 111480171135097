.nav {
  display: none;
  flex: 1 0 auto;
  min-height: 38px;
}

.nav__items, .nav__item {
  display: flex;
}

.nav__item {
  position: relative;
}

.navbar .nav {
  @include media-breakpoint-up(sm) {
    display: flex;
  }

  .nav__item {
    margin-left: 2rem;
  }

  .nav__link {
    display: flex;
    align-items: center;
  }

  &> .nav__item:hover,
  &> .nav__item--active {
    color: $primary;

    a {
      color: inherit;
    }
  }

  .nav__items--child-menu {
    display: none;
    position: absolute;
    left: -1rem;
    top: 100%;
    min-width: max-content;
    padding: 2rem 1rem 1rem 1rem;
    z-index: 10;

    &:before {
      position: absolute;
      content: "";
      background: white;
      width: 100%;
      height: calc(100% - 1rem);
      top: 1rem;
      left: 0;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }

    .nav__item {
      margin-left: 0;
    }

    .nav__link {
      width: 100%;
      padding: .25rem 0;
      justify-content: space-between;
    }

    .nav__icon {
      margin-left: 1rem;
    }
  }

  .nav__item:hover > .nav__items--child-menu {
    display: block;
  }
}

.nav-overlay .nav {
  display: flex;

  .nav__item {
    flex-wrap: wrap;
  }

  .nav__link {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    padding: .5rem 1rem;
    border-bottom: 1px $primary solid;
  }

  .nav__icon {
    position: relative;
    top: 5px;
  }

  .nav__items--child-menu .nav__link {
    padding: .5rem 1rem .5rem 1.5rem;
  }
}

.nav-overlay .nav__items {
  flex-direction: column;
}

.nav__items {
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;
}

.nav__item:first-child {
  margin-left: 0;
}

.nav__toggler {
  position: relative;
  padding: 0;
  border: 0;
  border-radius: 0;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
  background: transparent;

  &:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $primary;
    transition: all .4s;
  }

  span:nth-child(1) {
    top: 2px;
  }

  span:nth-child(2) {
    top: calc(50% - 1px);
  }

  span:nth-child(3) {
    bottom: 2px;
  }
}

.nav__toggler--active {
  span:nth-child(1) {
    top: calc(50% - 1px);
    transform: rotate(-45deg);
  }
  span:nth-child(2) {
    top: calc(50% - 1px);
    opacity: 0;
  }
  span:nth-child(3) {
    top: calc(50% - 1px);
    bottom: auto;
    transform: rotate(45deg);
  }
}

@include media-breakpoint-up(lg) {
  .nav-overlay,
  .nav__toggler {
    display: none;
  }
}

.nav-overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 3.25rem 0 0;
  font-size: $font-size-base;
  z-index: 1050;
  background: rgba(0, 0, 0, .2);
  width: 100%;
  height: 100%;
  text-align: center;
  transition: opacity 250ms ease-out;

  &.nav-overlay--active {
    display: flex;
    visibility: visible;
    opacity: 1;
    flex-direction: column;
    transition: opacity 250ms ease-in;
  }

  .nav-overlay__header {
    flex: 0 0 auto;

    .brand {
      margin: 1rem;
    }
  }

  .nav-overlay__body {
    flex: 1 0 auto;
    padding: 1rem;
  }
}

.nav-overlay__header,
.nav-overlay__body {
  background: #fff;
}


